import './App.css';
import WaterGrower from './features/water-grower/WaterGrower';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <WaterGrower />
      </header>
    </div>
  );
}

export default App;
