import React from "react";
import styled from "styled-components";

export default class WaterGrower extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      water: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.grow, 1000);
    window.addEventListener("click", this.reset);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  grow = () => {
    this.setState((prevState) => ({
      water: Math.min(150, prevState.water + 0.02),
    }));
  };

  reset = () => {
    this.setState({ water: 0 });
  };

  render() {
    const { isActive, water } = this.state;
    if(!isActive) {
      return (
        <Container>
        <StartButton onClick={() => this.setState({ isActive: true })}>
          start water reminder
        </StartButton>
        </Container>
      );
    }
    return (
      <Container>
        <WaterCircle water={water}>
          <DrinkText water={water}>drink water</DrinkText>
        </WaterCircle>
      </Container>
    );
  }
}


const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
`;

const StartButton = styled.button`
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const WaterCircle = styled.div`
  align-items: center;
  background-color: blue;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100px;
  opacity: ${(props) => props.water * 0.1};
  transform: scale(${(props) => props.water});
  width: 100px;
`;

const DrinkText = styled.div`
  color: white;
  cursor: pointer;
  font-size: 1px;
  font-weight: bold;
  opacity: ${(props) => props.water * 0.01};
  text-align: center;
  text-transform: uppercase;
`;
